export const SupportedStates = [
  {
    state: 'SD',
    fullName: 'South Dakota',
    urlName: 'sd',
    productName: 'SD Scores',
    sectionLabel: 'Region',
    sectionsLabel: 'Regions',
    seedpointLabel: 'Seedpoint',
    seedpointsLabel: 'Seedpoints',
    seedpointHeading: 'SP',
    sports: [
      {
        sport: 'FB',
        sportName: 'Football',
        fullSportName: 'Football',
        menuName: 'Football',
        urlName: 'football',
        gender: 'Boys',
        classes: ['11AAA', '11AA', '11A', '11B', '9AA', '9A', '9B'],
        classNames: ['11AAA', '11AA', '11A', '11B', '9AA', '9A', '9B'],
        periods: 'Quarter12OTAlternate',
        bracketHeading: 'Playoff Headquarters',
        lastRegSeasonGameDate: {
          '11AAA': '2022-10-23',
          '11AA': '2022-10-23',
          '11A': '2022-10-23',
          '11B': '2022-10-17',
          '9AA': '2022-10-17',
          '9A': '2022-10-17',
          '9B': '2022-10-17'
        },
        hasSections: {
          '11AAA': false,
          '11AA': false,
          '11A': false,
          '11B': false,
          '9AA': false,
          '9A': false,
          '9B': false
        },
        seedpoints: 'calculated',
        scorePages: [
          'WEEK 1',
          'WEEK 2',
          'WEEK 3',
          'WEEK 4',
          'WEEK 5',
          'WEEK 6',
          'WEEK 7',
          'WEEK 8',
          'WEEK 9',
          'WEEK 10',
          'WEEK 11',
          'WEEK 12',
          'WEEK 13'
        ]
      },
      {
        sport: 'VB',
        sportName: 'Volleyball',
        fullSportName: 'Volleyball',
        menuName: 'Volleyball',
        urlName: 'volleyball',
        gender: 'Girls',
        classes: ['AA', 'A', 'B'],
        classNames: ['AA', 'A', 'B'],
        periods: 'Set5',
        bracketHeading: 'Tournament Headquarters',
        firstGameDate: '2022-08-24',
        lastGameDate: '2022-11-20',
        // use day after last reg season game
        lastRegSeasonGameDate: {
          AA: '2022-11-07',
          A: '2022-10-27',
          B: '2022-10-27'
        },
        hasSections: {
          AA: false,
          A: true,
          B: true
        },
        seedpoints: 'calculated'
      },
      {
        sport: 'BBB',
        sportName: 'Basketball',
        fullSportName: 'Boys Basketball',
        menuName: 'Boys Basketball',
        urlName: 'boysbasketball',
        gender: 'Boys',
        classes: ['AA', 'A', 'B'],
        classNames: ['AA', 'A', 'B'],
        periods: 'Quarter8OT4',
        bracketHeading: 'Tournament Headquarters',
        firstGameDate: '2022-12-06',
        lastGameDate: '2023-03-19',
        lastRegSeasonGameDate: {
          AA: '2023-03-02',
          A: '2023-02-26',
          B: '2023-02-26'
        },
        hasSections: {
          AA: true,
          A: true,
          B: true
        },
        seedpoints: 'official'
      },
      {
        sport: 'GBB',
        sportName: 'Basketball',
        fullSportName: 'Girls Basketball',
        menuName: 'Girls Basketball',
        urlName: 'girlsbasketball',
        gender: 'Girls',
        classes: ['AA', 'A', 'B'],
        classNames: ['AA', 'A', 'B'],
        periods: 'Quarter8OT4',
        bracketHeading: 'Tournament Headquarters',
        firstGameDate: '2022-11-30',
        lastGameDate: '2023-03-12',
        lastRegSeasonGameDate: {
          AA: '2023-03-01',
          A: '2023-02-19',
          B: '2023-02-19'
        },
        hasSections: {
          AA: true,
          A: true,
          B: true
        },
        seedpoints: 'official'
      }
    ]
  },
  {
    state: 'MN',
    fullName: 'Minnesota',
    urlName: 'mn',
    productName: 'MN Scores',
    sectionLabel: 'Section',
    sectionsLabel: 'Sections',
    seedpointLabel: 'Rank point',
    seedpointsLabel: 'Rank points',
    seedpointHeading: 'RP',
    sports: [
      {
        sport: 'FB',
        sportName: 'Football',
        fullSportName: 'Football',
        menuName: 'Football',
        urlName: 'football',
        gender: 'Boys',
        classes: ['6A', '5A', '4A', '3A', '2A', '1A', '9'],
        classNames: ['AAAAAA', 'AAAAA', 'AAAA', 'AAA', 'AA', 'A', '9'],
        periods: 'Quarter12OTAlternate',
        bracketHeading: 'Playoff Headquarters',
        lastRegSeasonGameDate: {
          '6A': '2022-10-23',
          '5A': '2022-10-23',
          '4A': '2022-10-23',
          '3A': '2022-10-23',
          '2A': '2022-10-23',
          '1A': '2022-10-23',
          9: '2022-10-23'
        },
        hasSections: {
          '6A': true,
          '5A': true,
          '4A': true,
          '3A': true,
          '2A': true,
          '1A': true,
          9: true
        },
        seedpoints: 'calculated',
        scorePages: [
          'WEEK 0',
          'WEEK 1',
          'WEEK 2',
          'WEEK 3',
          'WEEK 4',
          'WEEK 5',
          'WEEK 6',
          'WEEK 7',
          'WEEK 8',
          'WEEK 9',
          'WEEK 10',
          'WEEK 11',
          'WEEK 12',
          'WEEK 13',
          'WEEK 14'
        ]
      },
      {
        sport: 'VB',
        sportName: 'Volleyball',
        fullSportName: 'Volleyball',
        menuName: 'Volleyball',
        urlName: 'volleyball',
        gender: 'Girls',
        classes: ['4A', '3A', '2A', '1A'],
        classNames: ['AAAA', 'AAA', 'AA', 'A'],
        periods: 'Set5',
        bracketHeading: 'Tournament Headquarters',
        firstGameDate: '2022-08-26',
        lastGameDate: '2022-11-13',
        lastRegSeasonGameDate: {
          '4A': '2022-10-25',
          '3A': '2022-10-25',
          '2A': '2022-10-25',
          '1A': '2022-10-25'
        },
        hasSections: {
          '4A': true,
          '3A': true,
          '2A': true,
          '1A': true
        },
        seedpoints: 'calculated'
      },
      {
        sport: 'BBB',
        sportName: 'Basketball',
        fullSportName: 'Boys Basketball',
        menuName: 'Boys Basketball',
        urlName: 'boysbasketball',
        gender: 'Boys',
        classes: ['4A', '3A', '2A', '1A'],
        classNames: ['AAAA', 'AAA', 'AA', 'A'],
        periods: 'Half18OT4',
        bracketHeading: 'Tournament Headquarters',
        firstGameDate: '2022-11-30',
        lastGameDate: '2023-04-15',
        lastRegSeasonGameDate: {
          '4A': '2023-03-12',
          '3A': '2023-03-12',
          '2A': '2023-03-12',
          '1A': '2023-03-12'
        },
        hasSections: {
          '4A': true,
          '3A': true,
          '2A': true,
          '1A': true
        },
        seedpoints: 'calculated'
      },
      {
        sport: 'GBB',
        sportName: 'Basketball',
        fullSportName: 'Girls Basketball',
        menuName: 'Girls Basketball',
        urlName: 'girlsbasketball',
        gender: 'Girls',
        classes: ['4A', '3A', '2A', '1A'],
        classNames: ['AAAA', 'AAA', 'AA', 'A'],
        periods: 'Half18OT4',
        bracketHeading: 'Tournament Headquarters',
        firstGameDate: '2022-11-20',
        lastGameDate: '2023-04-15',
        lastRegSeasonGameDate: {
          '4A': '2023-03-05',
          '3A': '2023-03-05',
          '2A': '2023-03-05',
          '1A': '2023-03-05'
        },
        hasSections: {
          '4A': true,
          '3A': true,
          '2A': true,
          '1A': true
        },
        seedpoints: 'calculated'
      },
      {
        sport: 'BH',
        sportName: 'Hockey',
        fullSportName: 'Boys Hockey',
        menuName: 'Boys Hockey',
        urlName: 'boyshockey',
        gender: 'Boys',
        classes: ['2A', '1A'],
        classNames: ['AA', 'A'],
        periods: 'Period17OT8',
        bracketHeading: 'Tournament Headquarters',
        firstGameDate: '2022-11-23',
        lastGameDate: '2023-04-15',
        lastRegSeasonGameDate: {
          '2A': '2023-02-22',
          '1A': '2023-02-22'
        },
        hasSections: {
          '2A': true,
          '1A': true
        },
        seedpoints: 'calculated'
      },
      {
        sport: 'GH',
        sportName: 'Hockey',
        fullSportName: 'Girls Hockey',
        menuName: 'Girls Hockey',
        urlName: 'girlshockey',
        gender: 'Girls',
        classes: ['2A', '1A'],
        classNames: ['AA', 'A'],
        periods: 'Period17OT8',
        bracketHeading: 'Tournament Headquarters',
        firstGameDate: '2022-11-11',
        lastGameDate: '2023-04-15',
        lastRegSeasonGameDate: {
          '2A': '2023-02-08',
          '1A': '2023-02-08'
        },
        hasSections: {
          '2A': true,
          '1A': true
        },
        seedpoints: 'calculated'
      }
    ]
  },
  {
    state: 'ND',
    fullName: 'North Dakota',
    urlName: 'nd',
    productName: 'ND ScoreFeed',
    sectionLabel: 'Region',
    sectionsLabel: 'Regions',
    seedpointLabel: 'Seedpoint',
    seedpointsLabel: 'Seedpoints',
    seedpointHeading: 'SP',
    sports: [
      {
        sport: 'FB',
        sportName: 'Football',
        fullSportName: 'Football',
        menuName: 'Football',
        urlName: 'football',
        gender: 'Boys',
        classes: ['11AA', '11A', '11B', '9B', 'IND', 'IND 6'],
        classNames: ['11AA', '11A', '11B', '9B', 'IND', 'IND 6'],
        periods: 'Quarter12OTAlternate',
        bracketHeading: 'Playoff Headquarters',
        lastRegSeasonGameDate: {
          '11AA': '2022-10-23',
          '11A': '2022-10-23',
          '11B': '2022-10-17',
          '9B': '2022-10-17',
          IND: '2022-10-17',
          'IND 6': '2022-10-17'
        },
        hasSections: {
          '11AA': true,
          '11A': true,
          '11B': true,
          '9B': true,
          IND: true,
          'IND 6': false
        },
        seedpoints: 'calculated',
        scorePages: [
          'WEEK 1',
          'WEEK 2',
          'WEEK 3',
          'WEEK 4',
          'WEEK 5',
          'WEEK 6',
          'WEEK 7',
          'WEEK 8',
          'WEEK 9',
          'WEEK 10',
          'WEEK 11',
          'WEEK 12',
          'WEEK 13'
        ]
      },
      {
        sport: 'VB',
        sportName: 'Volleyball',
        fullSportName: 'Volleyball',
        menuName: 'Volleyball',
        urlName: 'volleyball',
        gender: 'Girls',
        classes: ['A', 'B'],
        classNames: ['A', 'B'],
        periods: 'Set5',
        bracketHeading: 'Tournament Headquarters',
        firstGameDate: '2022-08-24',
        lastGameDate: '2022-11-20',
        // use day after last reg season game
        lastRegSeasonGameDate: {
          A: '2022-10-27',
          B: '2022-10-27'
        },
        hasSections: {
          A: true,
          B: true
        },
        seedpoints: 'calculated'
      },
      {
        sport: 'BBB',
        sportName: 'Basketball',
        fullSportName: 'Boys Basketball',
        menuName: 'Boys Basketball',
        urlName: 'boysbasketball',
        gender: 'Boys',
        classes: ['A', 'B'],
        classNames: ['A', 'B'],
        periods: 'Quarter8OT4',
        bracketHeading: 'Tournament Headquarters',
        firstGameDate: '2022-12-06',
        lastGameDate: '2023-03-19',
        lastRegSeasonGameDate: {
          A: '2023-02-26',
          B: '2023-02-26'
        },
        hasSections: {
          A: true,
          B: true
        },
        seedpoints: 'calculated'
      },
      {
        sport: 'GBB',
        sportName: 'Basketball',
        fullSportName: 'Girls Basketball',
        menuName: 'Girls Basketball',
        urlName: 'girlsbasketball',
        gender: 'Girls',
        classes: ['A', 'B'],
        classNames: ['A', 'B'],
        periods: 'Quarter8OT4',
        bracketHeading: 'Tournament Headquarters',
        firstGameDate: '2022-11-30',
        lastGameDate: '2023-03-12',
        lastRegSeasonGameDate: {
          A: '2023-02-19',
          B: '2023-02-19'
        },
        hasSections: {
          A: true,
          B: true
        },
        seedpoints: 'calculated'
      }
    ]
  },
  {
    state: 'NE',
    fullName: 'Nebraska',
    urlName: 'ne',
    productName: 'NE ScoreFeed',
    sectionLabel: 'District',
    sectionsLabel: 'Districts',
    seedpointLabel: 'Wildcard point',
    seedpointsLabel: 'Wildcard Points',
    seedpointHeading: 'PTS',
    sports: [
      {
        sport: 'FB',
        sportName: 'Football',
        fullSportName: 'Football',
        menuName: 'Football',
        urlName: 'football',
        gender: 'Boys',
        classes: ['A', 'B', 'C1', 'C2', 'D1', 'D2', 'D6'],
        classNames: ['A', 'B', 'C1', 'C2', 'D1', 'D2', 'D6'],
        periods: 'Quarter12OTAlternate',
        bracketHeading: 'Playoff Headquarters',
        lastRegSeasonGameDate: {
          A: '2022-11-07',
          B: '2022-10-27',
          C1: '2022-10-27',
          C2: '2022-11-07',
          D1: '2022-10-27',
          D2: '2022-10-27',
          D6: '2022-10-27'
        },
        hasSections: {
          A: true,
          B: true,
          C1: true,
          C2: true,
          D1: true,
          D2: true,
          D6: true
        },
        seedpoints: 'calculated',
        scorePages: [
          'WEEK 0',
          'WEEK 1',
          'WEEK 2',
          'WEEK 3',
          'WEEK 4',
          'WEEK 5',
          'WEEK 6',
          'WEEK 7',
          'WEEK 8',
          'WEEK 9',
          'WEEK 10',
          'WEEK 11',
          'WEEK 12'
        ]
      },
      {
        sport: 'VB',
        sportName: 'Volleyball',
        fullSportName: 'Volleyball',
        menuName: 'Volleyball',
        urlName: 'volleyball',
        gender: 'Girls',
        classes: ['A', 'B', 'C1', 'C2', 'D1', 'D2'],
        classNames: ['A', 'B', 'C1', 'C2', 'D1', 'D2'],
        periods: 'Set5',
        bracketHeading: 'Tournament Headquarters',
        firstGameDate: '2022-08-24',
        lastGameDate: '2022-11-20',
        // use day after last reg season game
        lastRegSeasonGameDate: {
          A: '2022-11-07',
          B: '2022-10-27',
          C1: '2022-10-27',
          C2: '2022-11-07',
          D1: '2022-10-27',
          D2: '2022-10-27'
        },
        hasSections: {
          A: true,
          B: true,
          C1: true,
          C2: true,
          D1: true,
          D2: true
        },
        seedpoints: 'calculated'
      },
      {
        sport: 'BBB',
        sportName: 'Basketball',
        fullSportName: 'Boys Basketball',
        menuName: 'Boys Basketball',
        urlName: 'boysbasketball',
        gender: 'Boys',
        classes: ['A', 'B', 'C1', 'C2', 'D1', 'D2'],
        classNames: ['A', 'B', 'C1', 'C2', 'D1', 'D2'],
        periods: 'Quarter8OT4',
        bracketHeading: 'Tournament Headquarters',
        firstGameDate: '2022-12-06',
        lastGameDate: '2023-03-19',
        lastRegSeasonGameDate: {
          A: '2023-03-02',
          B: '2023-03-02',
          C1: '2023-03-02',
          C2: '2023-03-02',
          D1: '2023-03-02',
          D2: '2023-03-02'
        },
        hasSections: {
          A: true,
          B: true,
          C1: true,
          C2: true,
          D1: true,
          D2: true
        },
        seedpoints: 'calculated'
      },
      {
        sport: 'GBB',
        sportName: 'Basketball',
        fullSportName: 'Girls Basketball',
        menuName: 'Girls Basketball',
        urlName: 'girlsbasketball',
        gender: 'Girls',
        classes: ['A', 'B', 'C1', 'C2', 'D1', 'D2'],
        classNames: ['A', 'B', 'C1', 'C2', 'D1', 'D2'],
        periods: 'Quarter8OT4',
        bracketHeading: 'Tournament Headquarters',
        firstGameDate: '2022-11-30',
        lastGameDate: '2023-03-12',
        lastRegSeasonGameDate: {
          A: '2023-03-02',
          B: '2023-03-02',
          C1: '2023-03-02',
          C2: '2023-03-02',
          D1: '2023-03-02',
          D2: '2023-03-02'
        },
        hasSections: {
          A: true,
          B: true,
          C1: true,
          C2: true,
          D1: true,
          D2: true
        },
        seedpoints: 'calculated'
      }
    ]
  },
  {
    state: 'IA',
    fullName: 'Iowa',
    urlName: 'ia',
    productName: 'IA ScoreFeed',
    sectionLabel: 'District',
    sectionsLabel: 'Districts',
    seedpointLabel: 'Rank point',
    seedpointsLabel: 'Rank points',
    seedpointHeading: 'RP',
    sports: [
      {
        sport: 'BBB',
        sportName: 'Basketball',
        fullSportName: 'Boys Basketball',
        menuName: 'Boys Basketball',
        urlName: 'boysbasketball',
        gender: 'Boys',
        classes: ['4A', '3A', '2A', '1A'],
        classNames: ['4A', '3A', '2A', '1A'],
        periods: 'Quarter8OT4',
        bracketHeading: 'Tournament Headquarters',
        firstGameDate: '2024-11-30',
        lastGameDate: '2025-04-15',
        lastRegSeasonGameDate: {
          '4A': '2025-03-12',
          '3A': '2025-03-12',
          '2A': '2025-03-12',
          '1A': '2025-03-12'
        },
        hasSections: {
          '4A': true,
          '3A': true,
          '2A': true,
          '1A': true
        },
        seedpoints: 'calculated'
      },
      {
        sport: 'GBB',
        sportName: 'Basketball',
        fullSportName: 'Girls Basketball',
        menuName: 'Girls Basketball',
        urlName: 'girlsbasketball',
        gender: 'Girls',
        classes: ['5A', '4A', '3A', '2A', '1A'],
        classNames: ['5A', '4A', '3A', '2A', '1A'],
        periods: 'Quarter8OT4',
        bracketHeading: 'Tournament Headquarters',
        firstGameDate: '2024-11-20',
        lastGameDate: '2025-04-15',
        lastRegSeasonGameDate: {
          '5A': '2025-03-05',
          '4A': '2025-03-05',
          '3A': '2025-03-05',
          '2A': '2025-03-05',
          '1A': '2025-03-05'
        },
        hasSections: {
          '5A': true,
          '4A': true,
          '3A': true,
          '2A': true,
          '1A': true
        },
        seedpoints: 'calculated'
      },
      {
        sport: 'FB',
        sportName: 'Football',
        fullSportName: 'Football',
        menuName: 'Football',
        urlName: 'football',
        gender: 'Boys',
        classes: ['5A', '4A', '3A', '2A', '1A', 'A', '8'],
        classNames: ['5A', '4A', '3A', '2A', '1A', 'A', '8'],
        periods: 'Quarter12OTAlternate',
        bracketHeading: 'Playoff Headquarters',
        lastRegSeasonGameDate: {
          '5A': '2024-10-23',
          '4A': '2024-10-23',
          '3A': '2024-10-23',
          '2A': '2024-10-23',
          '1A': '2024-10-23',
          A: '2024-10-23',
          8: '2024-10-23'
        },
        hasSections: {
          '5A': true,
          '4A': true,
          '3A': true,
          '2A': true,
          '1A': true,
          A: true,
          8: true
        },
        seedpoints: 'calculated'
      }
      // {
      //   sport: 'VB',
      //   sportName: 'Volleyball',
      //   fullSportName: 'Volleyball',
      //   menuName: 'Volleyball',
      //   urlName: 'volleyball',
      //   gender: 'Girls',
      //   classes: ['1A'],
      //   classNames: ['A'],
      //   periods: 'Set5',
      //   bracketHeading: 'Tournament Headquarters',
      //   firstGameDate: '2024-08-26',
      //   lastGameDate: '2024-11-13',
      //   lastRegSeasonGameDate: {
      //     '1A': '2024-10-25'
      //   },
      //   hasSections: {
      //     '1A': true
      //   },
      //   seedpoints: 'calculated'
      // }
    ]
  }
]

export function getFootballWeek(state, date) {
  let theWeek = ''
  let gameDate = new Date(date)
  // date is initially GMT, so add 11 hours to enable updates at 6:00am on game day
  gameDate.setHours(gameDate.getHours() + 11)

  if (state === 'SD') {
    if (gameDate <= new Date('2022-08-25')) theWeek = '1'
    else if (gameDate <= new Date('2022-09-01')) theWeek = '2'
    else if (gameDate <= new Date('2022-09-08')) theWeek = '3'
    else if (gameDate <= new Date('2022-09-15')) theWeek = '4'
    else if (gameDate <= new Date('2022-09-22')) theWeek = '5'
    else if (gameDate <= new Date('2022-09-29')) theWeek = '6'
    else if (gameDate <= new Date('2022-10-06')) theWeek = '7'
    else if (gameDate <= new Date('2022-10-13')) theWeek = '8'
    else if (gameDate <= new Date('2022-10-20')) theWeek = '9'
    else if (gameDate <= new Date('2022-10-27')) theWeek = '10'
    else if (gameDate <= new Date('2022-11-03')) theWeek = '11'
    else if (gameDate <= new Date('2022-11-10')) theWeek = '12'
    else theWeek = '13'
  }

  if (state === 'MN') {
    if (gameDate <= new Date('2022-09-01')) theWeek = '0'
    else if (gameDate <= new Date('2022-09-08')) theWeek = '1'
    else if (gameDate <= new Date('2022-09-15')) theWeek = '2'
    else if (gameDate <= new Date('2022-09-22')) theWeek = '3'
    else if (gameDate <= new Date('2022-09-29')) theWeek = '4'
    else if (gameDate <= new Date('2022-10-06')) theWeek = '5'
    else if (gameDate <= new Date('2022-10-13')) theWeek = '6'
    else if (gameDate <= new Date('2022-10-19')) theWeek = '7'
    else if (gameDate <= new Date('2022-10-22')) theWeek = '8'
    else if (gameDate <= new Date('2022-10-27')) theWeek = '9'
    else if (gameDate <= new Date('2022-10-31')) theWeek = '10'
    else if (gameDate <= new Date('2022-11-07')) theWeek = '11'
    else if (gameDate <= new Date('2022-11-14')) theWeek = '12'
    else if (gameDate <= new Date('2022-11-21')) theWeek = '13'
    else theWeek = '14'
  }

  if (state === 'ND') {
    if (gameDate <= new Date('2022-08-25')) theWeek = '1'
    else if (gameDate <= new Date('2022-09-01')) theWeek = '2'
    else if (gameDate <= new Date('2022-09-08')) theWeek = '3'
    else if (gameDate <= new Date('2022-09-15')) theWeek = '4'
    else if (gameDate <= new Date('2022-09-22')) theWeek = '5'
    else if (gameDate <= new Date('2022-09-29')) theWeek = '6'
    else if (gameDate <= new Date('2022-10-06')) theWeek = '7'
    else if (gameDate <= new Date('2022-10-13')) theWeek = '8'
    else if (gameDate <= new Date('2022-10-20')) theWeek = '9'
    else if (gameDate <= new Date('2022-10-27')) theWeek = '10'
    else if (gameDate <= new Date('2022-11-03')) theWeek = '11'
    else if (gameDate <= new Date('2022-11-10')) theWeek = '12'
    else theWeek = '13'
  }

  if (state === 'NE') {
    if (gameDate <= new Date('2022-08-25')) theWeek = '0'
    else if (gameDate <= new Date('2022-09-01')) theWeek = '1'
    else if (gameDate <= new Date('2022-09-08')) theWeek = '2'
    else if (gameDate <= new Date('2022-09-15')) theWeek = '3'
    else if (gameDate <= new Date('2022-09-22')) theWeek = '4'
    else if (gameDate <= new Date('2022-09-29')) theWeek = '5'
    else if (gameDate <= new Date('2022-10-06')) theWeek = '6'
    else if (gameDate <= new Date('2022-10-13')) theWeek = '7'
    else if (gameDate <= new Date('2022-10-20')) theWeek = '8'
    else if (gameDate <= new Date('2022-10-27')) theWeek = '9'
    else if (gameDate <= new Date('2022-11-03')) theWeek = '10'
    else if (gameDate <= new Date('2022-11-10')) theWeek = '11'
    else theWeek = '12'
  }

  return theWeek
}

export const gameStatusBB = {
  Quarter8OT4: {
    FINAL: 'Final',
    Pregame: 'Pregame',
    'Start of Period 1': 'Start of Period 1',
    '7:59-7:00 left in 1st': '7 min left in 1st',
    '6:59-6:00 left in 1st': '6 min left in 1st',
    '5:59-5:00 left in 1st': '5 min left in 1st',
    '4:59-4:00 left in 1st': '4 min left in 1st',
    '3:59-3:00 left in 1st': '3 min left in 1st',
    '2:59-2:00 left in 1st': '2 min left in 1st',
    '1:59-1:00 left in 1st': '1 min left in 1st',
    '0:59-0:00 left in 1st': 'under 1 min left in 1st',
    'End of Period 1': 'End of Period 1',
    'Start of Period 2': 'Start of Period 2',
    '7:59-7:00 left in 2nd': '7 min left in 2nd',
    '6:59-6:00 left in 2nd': '6 min left in 2nd',
    '5:59-5:00 left in 2nd': '5 min left in 2nd',
    '4:59-4:00 left in 2nd': '4 min left in 2nd',
    '3:59-3:00 left in 2nd': '3 min left in 2nd',
    '2:59-2:00 left in 2nd': '2 min left in 2nd',
    '1:59-1:00 left in 2nd': '1 min left in 2nd',
    '0:59-0:00 left in 2nd': 'under 1 min left in 2nd',
    'End of 1st Half': 'End of 1st Half',
    'Start of Period 3': 'Start of Period 3',
    '7:59-7:00 left in 3rd': '7 min left in 3rd',
    '6:59-6:00 left in 3rd': '6 min left in 3rd',
    '5:59-5:00 left in 3rd': '5 min left in 3rd',
    '4:59-4:00 left in 3rd': '4 min left in 3rd',
    '3:59-3:00 left in 3rd': '3 min left in 3rd',
    '2:59-2:00 left in 3rd': '2 min left in 3rd',
    '1:59-1:00 left in 3rd': '1 min left in 3rd',
    '0:59-0:00 left in 3rd': 'under 1 min left in 3rd',
    'End of Period 3': 'End of Period 3',
    'Start of Period 4': 'Start of Period 4',
    '7:59-7:00 left in 4th': '7 min left in 4th',
    '6:59-6:00 left in 4th': '6 min left in 4th',
    '5:59-5:00 left in 4th': '5 min left in 4th',
    '4:59-4:00 left in 4th': '4 min left in 4th',
    '3:59-3:00 left in 4th': '3 min left in 4th',
    '2:59-2:00 left in 4th': '2 min left in 4th',
    '1:59-1:00 left in 4th': '1 min left in 4th',
    '0:59-0:00 left in 4th': 'under 1 min left in 4th',
    Final: 'Final',
    'Start of Overtime': 'Start of Overtime',
    '3:59-3:00 left in OT': '3 min left in OT',
    '2:59-2:00 left in OT': '2 min left in OT',
    '1:59-1:00 left in OT': '1 min left in OT',
    '0:59-0:00 left in OT': 'under 1 min left in OT',
    'Final OT': 'Final OT',
    'Start of 2OT': 'Start of 2OT',
    '3:59-3:00 left in 2OT': '3 min left in 2OT',
    '2:59-2:00 left in 2OT': '2 min left in 2OT',
    '1:59-1:00 left in 2OT': '1 min left in 2OT',
    '0:59-0:00 left in 2OT': 'under 1 min left in 2OT',
    'Final 2OT': 'Final 2OT',
    'Start of 3OT': 'Start of 3OT',
    '3:59-3:00 left in 3OT': '3 min left in 3OT',
    '2:59-2:00 left in 3OT': '2 min left in 3OT',
    '1:59-1:00 left in 3OT': '1 min left in 3OT',
    '0:59-0:00 left in 3OT': 'under 1 min left in 3OT',
    'Final 3OT': 'Final 3OT',
    'Start of 4OT': 'Start of 4OT',
    '3:59-3:00 left in 4OT': '3 min left in 4OT',
    '2:59-2:00 left in 4OT': '2 min left in 4OT',
    '1:59-1:00 left in 4OT': '1 min left in 4OT',
    '0:59-0:00 left in 4OT': 'under 1 min left in 4OT',
    'Final 4OT': 'Final 4OT',
    'Start of 5OT': 'Start of 5OT',
    '3:59-3:00 left in 5OT': '3 min left in 5OT',
    '2:59-2:00 left in 5OT': '2 min left in 5OT',
    '1:59-1:00 left in 5OT': '1 min left in 5OT',
    '0:59-0:00 left in 5OT': 'under 1 min left in 5OT',
    'Final 5OT': 'Final 5OT'
  },
  Half18OT4: {
    FINAL: 'Final',
    Pregame: 'Pregame',
    'Start of 1st Half': 'Start of 1st Half',
    '17:59-17:00 left in 1st': '17 min left in 1st',
    '16:59-16:00 left in 1st': '16 min left in 1st',
    '15:59-15:00 left in 1st': '15 min left in 1st',
    '14:59-14:00 left in 1st': '14 min left in 1st',
    '13:59-13:00 left in 1st': '13 min left in 1st',
    '12:59-12:00 left in 1st': '12 min left in 1st',
    '11:59-11:00 left in 1st': '11 min left in 1st',
    '10:59-10:00 left in 1st': '10 min left in 1st',
    '9:59-9:00 left in 1st': '9 min left in 1st',
    '8:59-8:00 left in 1st': '8 min left in 1st',
    '7:59-7:00 left in 1st': '7 min left in 1st',
    '6:59-6:00 left in 1st': '6 min left in 1st',
    '5:59-5:00 left in 1st': '5 min left in 1st',
    '4:59-4:00 left in 1st': '4 min left in 1st',
    '3:59-3:00 left in 1st': '3 min left in 1st',
    '2:59-2:00 left in 1st': '2 min left in 1st',
    '1:59-1:00 left in 1st': '1 min left in 1st',
    '0:59-0:00 left in 1st': 'under 1 min left in 1st',
    'End of 1st Half': 'End of 1st Half',
    'Start of 2nd Half': 'Start of 2nd Half',
    '17:59-17:00 left in 2nd': '17 min left in 2nd',
    '16:59-16:00 left in 2nd': '16 min left in 2nd',
    '15:59-15:00 left in 2nd': '15 min left in 2nd',
    '14:59-14:00 left in 2nd': '14 min left in 2nd',
    '13:59-13:00 left in 2nd': '13 min left in 2nd',
    '12:59-12:00 left in 2nd': '12 min left in 2nd',
    '11:59-11:00 left in 2nd': '11 min left in 2nd',
    '10:59-10:00 left in 2nd': '10 min left in 2nd',
    '9:59-9:00 left in 2nd': '9 min left in 2nd',
    '8:59-8:00 left in 2nd': '8 min left in 2nd',
    '7:59-7:00 left in 2nd': '7 min left in 2nd',
    '6:59-6:00 left in 2nd': '6 min left in 2nd',
    '5:59-5:00 left in 2nd': '5 min left in 2nd',
    '4:59-4:00 left in 2nd': '4 min left in 2nd',
    '3:59-3:00 left in 2nd': '3 min left in 2nd',
    '2:59-2:00 left in 2nd': '2 min left in 2nd',
    '1:59-1:00 left in 2nd': '1 min left in 2nd',
    '0:59-0:00 left in 2nd': 'under 1 min left in 2nd',
    Final: 'Final',
    'Start of Overtime': 'Start of Overtime',
    '3:59-3:00 left in OT': '3 min left in OT',
    '2:59-2:00 left in OT': '2 min left in OT',
    '1:59-1:00 left in OT': '1 min left in OT',
    '0:59-0:00 left in OT': 'under 1 min left in OT',
    'Final OT': 'Final OT',
    'Start of 2OT': 'Start of 2OT',
    '3:59-3:00 left in 2OT': '3 min left in 2OT',
    '2:59-2:00 left in 2OT': '2 min left in 2OT',
    '1:59-1:00 left in 2OT': '1 min left in 2OT',
    '0:59-0:00 left in 2OT': 'under 1 min left in 2OT',
    'Final 2OT': 'Final 2OT',
    'Start of 3OT': 'Start of 3OT',
    '3:59-3:00 left in 3OT': '3 min left in 3OT',
    '2:59-2:00 left in 3OT': '2 min left in 3OT',
    '1:59-1:00 left in 3OT': '1 min left in 3OT',
    '0:59-0:00 left in 3OT': 'under 1 min left in 3OT',
    'Final 3OT': 'Final 3OT',
    'Start of 4OT': 'Start of 4OT',
    '3:59-3:00 left in 4OT': '3 min left in 4OT',
    '2:59-2:00 left in 4OT': '2 min left in 4OT',
    '1:59-1:00 left in 4OT': '1 min left in 4OT',
    '0:59-0:00 left in 4OT': 'under 1 min left in 4OT',
    'Final 4OT': 'Final 4OT',
    'Start of 5OT': 'Start of 5OT',
    '3:59-3:00 left in 5OT': '3 min left in 5OT',
    '2:59-2:00 left in 5OT': '2 min left in 5OT',
    '1:59-1:00 left in 5OT': '1 min left in 5OT',
    '0:59-0:00 left in 5OT': 'under 1 min left in 5OT',
    'Final 5OT': 'Final 5OT'
  }
}

export const gameStatusVB = {
  Set5: {
    Pregame: 'Pregame',
    'Set 1': 'Set 1',
    'End of Set 1': 'End of Set 1',
    'Set 2': 'Set 2',
    'End of Set 2': 'End of Set 2',
    'Set 3': 'Set 3',
    'End of Set 3': 'End of Set 3',
    'Set 4': 'Set 4',
    'End of Set 4': 'End of Set 4',
    'Set 5': 'Set 5',
    'End of Set 5': 'End of Set 5',
    Final: 'Final'
  }
}

export const gameStatusFB = {
  Quarter12OTAlternate: {
    FINAL: 'Final',
    Pregame: 'Pregame',
    'Start of Period 1': 'Start of Period 1',
    '11:59-11:00 left in 1st': '11 min left in 1st',
    '10:59-10:00 left in 1st': '10 min left in 1st',
    '9:59-9:00 left in 1st': '9 min left in 1st',
    '8:59-8:00 left in 1st': '8 min left in 1st',
    '7:59-7:00 left in 1st': '7 min left in 1st',
    '6:59-6:00 left in 1st': '6 min left in 1st',
    '5:59-5:00 left in 1st': '5 min left in 1st',
    '4:59-4:00 left in 1st': '4 min left in 1st',
    '3:59-3:00 left in 1st': '3 min left in 1st',
    '2:59-2:00 left in 1st': '2 min left in 1st',
    '1:59-1:00 left in 1st': '1 min left in 1st',
    '0:59-0:00 left in 1st': 'under 1 min left in 1st',
    'End of Period 1': 'End of Period 1',
    'Start of Period 2': 'Start of Period 2',
    '11:59-11:00 left in 2nd': '11 min left in 2nd',
    '10:59-10:00 left in 2nd': '10 min left in 2nd',
    '9:59-9:00 left in 2nd': '9 min left in 2nd',
    '8:59-8:00 left in 2nd': '8 min left in 2nd',
    '7:59-7:00 left in 2nd': '7 min left in 2nd',
    '6:59-6:00 left in 2nd': '6 min left in 2nd',
    '5:59-5:00 left in 2nd': '5 min left in 2nd',
    '4:59-4:00 left in 2nd': '4 min left in 2nd',
    '3:59-3:00 left in 2nd': '3 min left in 2nd',
    '2:59-2:00 left in 2nd': '2 min left in 2nd',
    '1:59-1:00 left in 2nd': '1 min left in 2nd',
    '0:59-0:00 left in 2nd': 'under 1 min left in 2nd',
    'End of 1st Half': 'End of 1st Half',
    'Start of Period 3': 'Start of Period 3',
    '11:59-11:00 left in 3rd': '11 min left in 3rd',
    '10:59-10:00 left in 3rd': '10 min left in 3rd',
    '9:59-9:00 left in 3rd': '9 min left in 3rd',
    '8:59-8:00 left in 3rd': '8 min left in 3rd',
    '7:59-7:00 left in 3rd': '7 min left in 3rd',
    '6:59-6:00 left in 3rd': '6 min left in 3rd',
    '5:59-5:00 left in 3rd': '5 min left in 3rd',
    '4:59-4:00 left in 3rd': '4 min left in 3rd',
    '3:59-3:00 left in 3rd': '3 min left in 3rd',
    '2:59-2:00 left in 3rd': '2 min left in 3rd',
    '1:59-1:00 left in 3rd': '1 min left in 3rd',
    '0:59-0:00 left in 3rd': 'under 1 min left in 3rd',
    'End of Period 3': 'End of Period 3',
    'Start of Period 4': 'Start of Period 4',
    '11:59-11:00 left in 4th': '11 min left in 4th',
    '10:59-10:00 left in 4th': '10 min left in 4th',
    '9:59-9:00 left in 4th': '9 min left in 4th',
    '8:59-8:00 left in 4th': '8 min left in 4th',
    '7:59-7:00 left in 4th': '7 min left in 4th',
    '6:59-6:00 left in 4th': '6 min left in 4th',
    '5:59-5:00 left in 4th': '5 min left in 4th',
    '4:59-4:00 left in 4th': '4 min left in 4th',
    '3:59-3:00 left in 4th': '3 min left in 4th',
    '2:59-2:00 left in 4th': '2 min left in 4th',
    '1:59-1:00 left in 4th': '1 min left in 4th',
    '0:59-0:00 left in 4th': 'under 1 min left in 4th',
    Final: 'Final',
    'Start of Overtime': 'Start of Overtime',
    "First team's ball in OT": "First team's ball in OT",
    "Second team's ball in OT": "Second team's ball in OT",
    'Final OT': 'Final OT',
    'Start of 2OT': 'Start of 2OT',
    "First team's ball in 2OT": "First team's ball in 2OT",
    "Second team's ball in 2OT": "Second team's ball in 2OT",
    'Final 2OT': 'Final 2OT',
    'Start of 3OT': 'Start of 3OT',
    "First team's ball in 3OT": "First team's ball in 3OT",
    "Second team's ball in 3OT": "Second team's ball in 3OT",
    'Final 3OT': 'Final 3OT',
    'Start of 4OT': 'Start of 4OT',
    "First team's ball in 4OT": "First team's ball in 4OT",
    "Second team's ball in 4OT": "Second team's ball in 4OT",
    'Final 4OT': 'Final 4OT',
    'Start of 5OT': 'Start of 5OT',
    "First team's ball in 5OT": "First team's ball in 5OT",
    "Second team's ball in 5OT": "Second team's ball in 5OT",
    'Final 5OT': 'Final 5OT'
  }
}

export const gameStatusH = {
  Period17OT8: {
    FINAL: 'Final',
    Pregame: 'Pregame',
    'Start of Period 1': 'Start of Period 1',
    '16:59-16:00 left in 1st': '16 min left in 1st',
    '15:59-15:00 left in 1st': '15 min left in 1st',
    '14:59-14:00 left in 1st': '14 min left in 1st',
    '13:59-13:00 left in 1st': '13 min left in 1st',
    '12:59-12:00 left in 1st': '12 min left in 1st',
    '11:59-11:00 left in 1st': '11 min left in 1st',
    '10:59-10:00 left in 1st': '10 min left in 1st',
    '9:59-9:00 left in 1st': '9 min left in 1st',
    '8:59-8:00 left in 1st': '8 min left in 1st',
    '7:59-7:00 left in 1st': '7 min left in 1st',
    '6:59-6:00 left in 1st': '6 min left in 1st',
    '5:59-5:00 left in 1st': '5 min left in 1st',
    '4:59-4:00 left in 1st': '4 min left in 1st',
    '3:59-3:00 left in 1st': '3 min left in 1st',
    '2:59-2:00 left in 1st': '2 min left in 1st',
    '1:59-1:00 left in 1st': '1 min left in 1st',
    '0:59-0:00 left in 1st': 'under 1 min left in 1st',
    'End of Period 1': 'End of Period 1',
    'Start of Period 2': 'Start of Period 2',
    '16:59-16:00 left in 2nd': '16 min left in 2nd',
    '15:59-15:00 left in 2nd': '15 min left in 2nd',
    '14:59-14:00 left in 2nd': '14 min left in 2nd',
    '13:59-13:00 left in 2nd': '13 min left in 2nd',
    '12:59-12:00 left in 2nd': '12 min left in 2nd',
    '11:59-11:00 left in 2nd': '11 min left in 2nd',
    '10:59-10:00 left in 2nd': '10 min left in 2nd',
    '9:59-9:00 left in 2nd': '9 min left in 2nd',
    '8:59-8:00 left in 2nd': '8 min left in 2nd',
    '7:59-7:00 left in 2nd': '7 min left in 2nd',
    '6:59-6:00 left in 2nd': '6 min left in 2nd',
    '5:59-5:00 left in 2nd': '5 min left in 2nd',
    '4:59-4:00 left in 2nd': '4 min left in 2nd',
    '3:59-3:00 left in 2nd': '3 min left in 2nd',
    '2:59-2:00 left in 2nd': '2 min left in 2nd',
    '1:59-1:00 left in 2nd': '1 min left in 2nd',
    '0:59-0:00 left in 2nd': 'under 1 min left in 2nd',
    'End of Period 2': 'End of Period 2',
    'Start of Period 3': 'Start of Period 3',
    '16:59-16:00 left in 3rd': '16 min left in 3rd',
    '15:59-15:00 left in 3rd': '15 min left in 3rd',
    '14:59-14:00 left in 3rd': '14 min left in 3rd',
    '13:59-13:00 left in 3rd': '13 min left in 3rd',
    '12:59-12:00 left in 3rd': '12 min left in 3rd',
    '11:59-11:00 left in 3rd': '11 min left in 3rd',
    '10:59-10:00 left in 3rd': '10 min left in 3rd',
    '9:59-9:00 left in 3rd': '9 min left in 3rd',
    '8:59-8:00 left in 3rd': '8 min left in 3rd',
    '7:59-7:00 left in 3rd': '7 min left in 3rd',
    '6:59-6:00 left in 3rd': '6 min left in 3rd',
    '5:59-5:00 left in 3rd': '5 min left in 3rd',
    '4:59-4:00 left in 3rd': '4 min left in 3rd',
    '3:59-3:00 left in 3rd': '3 min left in 3rd',
    '2:59-2:00 left in 3rd': '2 min left in 3rd',
    '1:59-1:00 left in 3rd': '1 min left in 3rd',
    '0:59-0:00 left in 3rd': 'under 1 min left in 3rd',
    Final: 'Final',
    'Start of Overtime': 'Start of Overtime',
    '7:59-7:00 left in OT': '7 min left in OT',
    '6:59-6:00 left in OT': '6 min left in OT',
    '5:59-5:00 left in OT': '5 min left in OT',
    '4:59-4:00 left in OT': '4 min left in OT',
    '3:59-3:00 left in OT': '3 min left in OT',
    '2:59-2:00 left in OT': '2 min left in OT',
    '1:59-1:00 left in OT': '1 min left in OT',
    '0:59-0:00 left in OT': 'under 1 min left in OT',
    'Final OT': 'Final OT'
  }
}

export const mascotColors = {
  '#029132': 'mascot-green',
  '#015C20': 'mascot-darkgreen',
  '#F24400': 'mascot-orange',
  '#E00000': 'mascot-red',
  '#800000': 'mascot-maroon',
  '#7FC3FF': 'mascot-lightblue',
  '#0148FF': 'mascot-blue',
  '#002E72': 'mascot-darkblue',
  '#7E00CB': 'mascot-purple',
  '#000000': 'mascot-black'
}

export const initialTeamState = {
  team: '',
  displayName: '',
  class: '',
  region: '',
  conference: [],
  seedpoints: '',
  wins: '',
  losses: '',
  schedule: [],
  roster: [],
  contactCoach: {
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  },
  contactSchool: {
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  },
  contactLeader: {
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  },
  color: '',
  mascot: '-',
  adInfo: {
    premierSponsors: [],
    teamSponsors: []
  },
  location: [
    {
      name: '',
      videolink: '',
      address: ''
    }
  ],
  media: [],
  facebook: '',
  website: ''
}

export const initialGameState = {
  week: '',
  time: '',
  gameID: '',
  dateID: '',
  dateTime: '',
  team1: '',
  team1displayName: '',
  team1class: '',
  team1region: '',
  team1score: '',
  team2: '',
  team2displayName: '',
  team2class: '',
  team2region: '',
  team2score: '',
  timeRemaining: '',
  location: '',
  videoFeed: '',
  numUpdates: 0,
  numUpdaters: 0,
  lastUpdated: '',
  certified: false,
  note: '',
  message: '',
  deleted: false
}

export const bracketTypes = [
  {
    name: 'List (2 games)',
    type: 'list',
    columns: 1,
    rows: 2,
    games: 2,
    champion: 0,
    columnNames: ['Games']
  },
  {
    name: 'List (4 games)',
    type: 'list',
    columns: 1,
    rows: 4,
    games: 4,
    champion: 0,
    columnNames: ['Games']
  },
  {
    name: 'List (8 games)',
    type: 'list',
    columns: 1,
    rows: 8,
    games: 8,
    champion: 0,
    columnNames: ['Games']
  },
  {
    name: 'List (12 games)',
    type: 'list',
    columns: 1,
    rows: 12,
    games: 12,
    champion: 0,
    columnNames: ['Games']
  },
  {
    name: 'List (16 games)',
    type: 'list',
    columns: 1,
    rows: 16,
    games: 16,
    champion: 0,
    columnNames: ['Games']
  },
  {
    name: 'List (32 games)',
    type: 'list',
    columns: 1,
    rows: 32,
    games: 32,
    champion: 0,
    columnNames: ['Games']
  },
  {
    name: 'Consolation (4 team)',
    type: 'consolation',
    columns: 3,
    rows: 2,
    games: 4,
    champion: 4,
    columnNames: ['Round 2', 'Round 1', 'Round 2']
  },
  {
    name: 'Consolation (8 team)',
    type: 'consolation',
    columns: 5,
    rows: 5,
    games: 12,
    champion: 11,
    columnNames: ['Round 3', 'Round 2', 'Round 1', 'Round 2', 'Round 3']
  },
  {
    name: 'Traditional (4 team)',
    type: 'traditional',
    columns: 2,
    rows: 2,
    games: 3,
    champion: 3,
    columnNames: ['Round 1', 'Round 2']
  },
  {
    name: 'Traditional (8 team)',
    type: 'traditional',
    columns: 3,
    rows: 4,
    games: 7,
    champion: 7,
    columnNames: ['Round 1', 'Round 2', 'Round 3']
  },
  {
    name: 'Traditional (16 team)',
    type: 'traditional',
    columns: 4,
    rows: 8,
    games: 15,
    champion: 15,
    columnNames: ['Round 1', 'Round 2', 'Round 3', 'Round 4']
  },
  {
    name: 'Traditional (32 team)',
    type: 'traditional',
    columns: 5,
    rows: 16,
    games: 31,
    champion: 31,
    columnNames: ['Round 1', 'Round 2', 'Round 3', 'Round 4', 'Round 5']
  }
]

export const initialBracketGameState = {
  dateID: '',
  gameID: '',
  label: '',
  rank1: '',
  team1: '',
  team1displayName: '',
  score1: '',
  rank2: '',
  team2: '',
  team2displayName: '',
  score2: '',
  time: '',
  location: '',
  show: true
}

export const initialPollState = {
  id: '',
  organization: '',
  title: 'New poll',
  date: '',
  class: '',
  numRanked: '10',
  teams: []
}

export const initialPlayerState = {
  number: '',
  name: '',
  position: '',
  height: '',
  weight: '',
  grade: ''
}

export const weights = [
  '100',
  '105',
  '110',
  '115',
  '120',
  '125',
  '130',
  '135',
  '140',
  '145',
  '150',
  '155',
  '160',
  '165',
  '170',
  '175',
  '180',
  '185',
  '190',
  '195',
  '200',
  '205',
  '210',
  '215',
  '220',
  '225',
  '230',
  '245',
  '250',
  '255',
  '260',
  '265',
  '270',
  '275',
  '280',
  '285',
  '290',
  '295',
  '300'
]

export const heights = [
  `5'0"`,
  `5'1"`,
  `5'2"`,
  `5'3"`,
  `5'4"`,
  `5'5"`,
  `5'6"`,
  `5'7"`,
  `5'8"`,
  `5'9"`,
  `5'10"`,
  `5'11"`,
  `6'0"`,
  `6'1"`,
  `6'2"`,
  `6'3"`,
  `6'4"`,
  `6'5"`,
  `6'6"`,
  `6'7"`,
  `6'8"`,
  `6'9"`,
  `6'10"`,
  `6'11"`,
  `7'0"`,
  `7'1"`,
  `7'2"`
]

export const stateAbbreviations = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
  'JV'
]
